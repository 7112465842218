<template>
    <div id="second">
        <CSBreadcrumb/>
        <div class="result-panel">
            <div style=
                    "
                        padding:15px 30px;
                        color:#000;
                        font-weight:bold;
                    ">
                <span
                    style="margin-right:30px"
                >监控编号：{{cameraId}}</span>
                <span>位置：{{position}}</span>
            </div>
            <table
                class='footable table table-stripped toggle-arrow-tiny'
                data-page-size="15"
            >
                <thead>
                    <tr>
                        <td>日期</td>
                        <td>录像</td>
                        <td>操作</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td>
                            <span
                                class="allow-click"
                                @click="checkVideo()"
                            >2</span>
                        </td>
                        <td>
                            <button
                                type="button"
                                class="btn btn-primary"
                                @click="downloadVideo()"
                            >
                                下载
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <TargetPersonVideo></TargetPersonVideo>
    </div>
</template>

<script>
import CSBreadcrumb from "@/components/common/CSBreadcrumb"
import TargetPersonVideo from "@/components/SecurityCamera/targetPersonVideo";

export default {
    components:{
        CSBreadcrumb,
        TargetPersonVideo,
    },
    data(){
        return {
            cameraId:"",
            position:"",
        }
    },
    created(){
        this.cameraId = this.$route.params.cameraId;
        this.position = this.$route.params.position;
        this.getVideoData();
    },
    methods:{
        getVideoData(){
            console.log("获取对应摄像头录像数据");
        },
        downloadVideo(){
            console.log("downloadVideo");
        },
        checkVideo(){
            var data = {
                boolean:true,
            }
            this.$vc.emit(this.$route.path, "videoSecondDetail","checkVideo",data)
        }
    }
}
</script>

<style>

</style>
